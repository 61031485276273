<template>
  <!--生产配料单 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>

<script>
import TablePage from "@/components/tablePage"; //表格页面组件
import {
  listBillInfo,
  modifyBillStatus,
  rawMaterialOrdering,
  halfGoodsProduce,
  rawMaterialMoveIn,
  materialpickMaterial,
  listImportBillInfo,
} from "@/api/produce/bill/matchMaterial"; //生产配料单
export default {
  name: "MatchMaterial",
  components: { TablePage },
  data() {
    return {
      options: {
        uuid: "3437bd1d-38b0-ce1a-7f71-4a5c8a8f507d",
        mutiSelect: true, // 多选
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        getListApi: listBillInfo,
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: "billId",
        //搜索
        search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            noReset: true,
            filterFnc: ([a, b] = []) => ({
              beginBillDate: a + " 00:00:00",
              endBillDate: b + " 23:59:59",
            }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          { type: "button", tip: "重置", click: "reset" },
        ],
        //按钮
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
          },
          {
            click: "audit",
            label: "审核",
            alertText: "确认要审核选中单据吗？",
            btnType: "dropdown",
            type: "success",
            other: [
              {
                click: "reverseAudit",
                label: "反审核",
                alertText: "确认要反审核选中单据吗？",
              },
            ],
          },
          {
            click: "rawMaterial",
            label: "原料订货",
            type: "primary",
          },
          {
            click: "semiFinished",
            label: "半成品生产",
            type: "primary",
          },
          {
            click: "transferIn",
            label: "原料调入",
            type: "primary",
          },
          {
            click: "receive",
            label: "配料领用",
            type: "primary",
          },
          {
            label: "打印",
            type: "primary",
            btnType: "dropdown",
            print: {},
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
          },
          {
            click: "getRelevantDocuments",
            label: "相关单据",
            type: "primary",
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        //表格
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            type: "link",
            click: "update",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "handBillNo",
            label: "手工单据号",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "billStatusName",
            label: "单据状态",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "produceDeptName",
            label: "生产工厂",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "materialOrderBillNo",
            label: "订货单号",
            minWidth: 150,
            align: "center",
            type: "link",
            click: "materialOrder",
          },
          {
            prop: "moveInBillNo",
            label: "调入单号",
            minWidth: 150,
            align: "center",
            type: "link",
            click: "moveIn",
          },
          {
            prop: "leadMaterialBillNo",
            label: "领料单号",
            minWidth: 150,
            align: "center",
            type: "link",
            click: "leadMaterial",
          },
          {
            prop: "materialOrderStatusName",
            label: "订货状态",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "moveInStatusName",
            label: "调入状态",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "leadMaterialStatusName",
            label: "领料状态",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "billRemark",
            label: "备注",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 155,
            align: "center",
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "createTime",
            label: "创建日期",
            minWidth: 155,
            align: "center",
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "updateTime",
            label: "修改日期",
            minWidth: 155,
            align: "center",
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      let billIds = [];
      switch (type) {
        case "add":
          this.$router.push({
            name: "MatchMaterialDetail",
          });
          break;
        case "update":
          this.$router.push({
            name: "MatchMaterialDetail",
            query: {
              billId: row.billId,
              type: "Update",
            },
          });
          break;
        case "audit":
          this.options.check.forEach((item) => {
            if (item.billStatus == "0") billIds.push(item.billId);
          });
          if (billIds.length >= 1) {
            try {
              await modifyBillStatus({
                billIds,
                billStatus: 2,
              });
              this.$message.success("审核成功");
              this.$refs.tablePage.getList();
            } catch (err) {}
          } else {
            this.$message.error("请选择未审核的单据");
          }
          break;
        case "reverseAudit":
          this.options.check.forEach((item) => {
            if (item.billStatus == "2") billIds.push(item.billId);
          });
          if (billIds.length >= 1) {
            try {
              await modifyBillStatus({
                billIds,
                billStatus: 0,
              });
              this.$message.success("反审核成功");
              this.$refs.tablePage.getList();
            } catch (err) {}
          } else {
            this.$message.error("请选择已审核的单据");
          }
          break;
        case "del":
          this.options.check.forEach((item) => {
            if (item.billStatus == "0") billIds.push(item.billId);
          });
          if (billIds.length >= 1) {
            try {
              await modifyBillStatus({
                billIds,
                billStatus: 3,
              });
              this.$message.success("删除成功");
              this.$refs.tablePage.getList();
            } catch (err) {}
          } else {
            this.$message.error("请选择未审核的单据");
          }
          break;
        case "rawMaterial":
          //原料订货 => 其他订货单
          if (this.options.check.length > 1) {
            return this.$message.error("每次只能选择一个单据！");
          }
          this.options.check.forEach((item) => {
            if (item.billStatus == "2") billIds.push(item.billId);
          });
          if (billIds.length == 1) {
            const res = await rawMaterialOrdering({
              billId: billIds[0],
            });
            this.$router.push({
              path: "/produce/other/storeMaterialOrder/storeMaterialOrderDetail",
              query: {
                orderInfo: JSON.stringify(res.data),
                type: "matchMaterialOrder",
              },
            });
          } else {
            return this.$message.error("请选择已审核的单据");
          }
          break;
        case "semiFinished":
          //半成品生产 => 生产计划单
          if (this.options.check.length > 1) {
            return this.$message.error("每次只能选择一个单据！");
          }
          this.options.check.forEach((item) => {
            if (item.billStatus == "2") billIds.push(item.billId);
          });
          if (billIds.length == 1) {
            const res = await halfGoodsProduce({
              billId: billIds[0],
            });
            const timestampId = Date.now();
            this.$router.push({
              path: "/produce/bill/plan/planAdd",
              query: {
                type: "add",
                timestampId,
                orderInfo: JSON.stringify(res.data),
              },
            });
          } else {
            return this.$message.error("请选择已审核的单据");
          }
          break;
        case "transferIn":
          //原料调入 => 仓库调入
          if (this.options.check.length > 1) {
            return this.$message.error("每次只能选择一个单据！");
          }
          this.options.check.forEach((item) => {
            if (item.billStatus == "2") billIds.push(item.billId);
          });
          if (billIds.length == 1) {
            const res = await rawMaterialMoveIn({
              billId: billIds[0],
            });
            this.$router.push({
              name: "storeMoveInDetail",
              query: {
                orderInfo: JSON.stringify(res.data),
                type: "Add",
              },
            });
          } else {
            return this.$message.error("请选择已审核的单据");
          }
          break;
        case "receive":
          //配料领用 => 生产领料
          if (this.options.check.length > 1) {
            return this.$message.error("每次只能选择一个单据！");
          }
          this.options.check.forEach((item) => {
            if (item.billStatus == "2") billIds.push(item.billId);
          });
          if (billIds.length == 1) {
            const res = await materialpickMaterial({
              billId: billIds[0],
            });
            this.$router.push({
              name: "leadMaterialDetail",
              query: {
                orderInfo: JSON.stringify(res.data),
                type: "matchMaterialOrder",
              },
            });
          } else {
            return this.$message.error("请选择已审核的单据");
          }
          break;
        case "materialOrder":
          this.$router.push({
            path: "/produce/other/storeMaterialOrder/storeMaterialOrderDetail",
            query: {
              billId: row.materialOrderBillId,
              type: "Update",
            },
          });
          break;
        case "moveIn":
          this.$router.push({
            name: "storeMoveInDetail",
            query: {
              billId: row.moveInBillId,
              type: "Update",
            },
          });
          break;
        case "leadMaterial":
          this.$router.push({
            name: "leadMaterialDetail",
            query: {
              billId: row.leadMaterialBillId,
              type: "Update",
            },
          });
          break;
        case "getRelevantDocuments":
          if (this.options.check.length != 1) {
            return this.$message.error("请勾选一条数据查询相关单据！");
          }
          const res = await listImportBillInfo(this.options.check[0].billId);
          let data = res.data || [];
          this.$router.push({
            name: "produceMatchMaterialRelevant",
            query: {
              type: "produceMatchMaterial",
              data: JSON.stringify(data),
            },
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>
